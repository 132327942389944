import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Layout,
    children: [
      {
        path: '/',
        name: 'home',
        component: () => import(/* webpackChunkName: "home" */ '../views/home'),
        meta: {
          title: '首页',
        },
      },
      {
        path: '/versionIntroduction',
        name: 'versionIntroduction',
        component: () =>
          import(
            /* webpackChunkName: "versionIntroduction" */ '../views/versionIntroduction'
          ),
        meta: {
          title: '版本介绍',
        },
      },
      {
        path: '/reward',
        name: 'reward',
        component: () =>
          import(/* webpackChunkName: "reward" */ '../views/reward'),
        meta: {
          title: '打赏',
        },
      },
      {
        path: '/contactUs',
        name: 'contactUs',
        component: () =>
          import(/* webpackChunkName: "contactUs" */ '../views/contactUs'),
        meta: {
          title: '联系作者',
        },
      },
      {
        path: '/video',
        name: 'video',
        component: () =>
          import(/* webpackChunkName: "contactUs" */ '../views/video'),
        meta: {
          title: '视频教程',
        },
      },
      {
        path: '/record',
        name: 'record',
        component: () =>
          import(
            /* webpackChunkName: "versionIntroduction" */ '../views/record'
          ),
        meta: {
          title: '更新记录',
        },
      },
    ],
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.afterEach(() => {
  window.scrollTo(0, 0)
})

export default router
