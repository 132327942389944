import { render, staticRenderFns } from "./App.vue?vue&type=template&id=4c2f0757"
var script = {}


/* normalize component */
import normalizer from "!../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.4.30_css-loader@6.11.0_webpack@5.92.1__lodash@4.17.21__hdz7x5femy7groiojlpkapohuu/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports