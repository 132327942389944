<template>
  <div class="page-footer df-c">
    <div class="main-container">
      <div class="content df-c-b">
        <div class="left">
          <div class="logo df-c">
            <img src="../../assets/logo.png" />
            <span class="sys-name">SpringReport</span>
          </div>
          <div class="url">www.springreport.vip</div>
          <div class="contact-info">
            <img src="../../assets/qq.png" />
            <img src="../../assets/wechat.png" />
            <img src="../../assets/email.png" />
          </div>
        </div>
        <div class="right df">
        </div>
      </div>
      <div class="line"></div>
      <div class="copyright">Copyright © 2022 springreport.vip. All rights reserved.</div>
      <div class="copyright" style="margin-top:8px">
        <img src="../../assets/aicon.png" width="20px">
        <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=37021302001006" target="_blank">鲁公网安备 37021302001006号</a>
        <a href="https://beian.miit.gov.cn/" target="_blank" style="margin-left:16px">鲁ICP备2021031250号-2</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageFooter',
  data() {
    return {
    }
  },
  components: {},
  watch: {},
  mounted() {

  },
  methods: {},
}
</script>

<style scoped lang="scss">
.page-footer {
  height: 312px;
  background: #1f242a;
  .left {
    .logo {
      img {
        height: 28px;
      }
      .sys-name {
        margin-left: 8px;
        font-weight: 400;
        font-size: 24px;
        line-height: 34px;
        letter-spacing: 0.01em;
        color: #ffffff;
      }
    }
    .url {
      margin-top: 16px;
      font-size: 18px;
      line-height: 25px;
      letter-spacing: 0.05em;
      color: #ffffff;
    }
    .contact-info {
      margin-top: 16px;
      img {
        width: 40px;
        height: 40px;
        margin-right: 20px;
      }
    }
  }
  .right {
    min-height: 100%;
    .qq {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      p,
      a {
        font-size: 16px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0.01em;
        color: #ffffff;
      }
    }
    .qq-gruop {
      margin: 0 50px;
    }
    .qr-code {
      .qr-code-box {
        text-align: center;
        &:first-child {
          margin-right: 24px;
        }
        &:nth-child(2) {
          margin-right: 24px;
        }
        p {
          margin-top: 4px;
          height: 18px;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          color: #ffffff;
        }
        img {
          width: 100px;
          height: 100px;
        }
      }
    }
  }

  .line {
    width: 100%;
    border-bottom: 1px solid rgba(56, 76, 81, 0.85);
    margin: 32px 0;
  }
  .copyright {
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #ffffff;
    a{
        color: #ffffff !important;
      }
  }
}
@media screen and (max-width: 1200px) {
  .page-footer {
    height: auto;
    padding: 43px 0;
    .content {
      display: block;
      .left {
        text-align: center;
        .logo {
          width: fit-content;
          margin: 0 auto;
          img {
            width: 20px;
            height: 20px;
          }
          .sys-name {
            height: 20px;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.01em;
            color: #ffffff;
            margin-left: 6px;
          }
        }
        .url {
          margin-top: 8px;
          font-weight: 300;
          font-size: 12px;
          line-height: 17px;
          letter-spacing: 0.05em;
          color: #ffffff;
        }
        .contact-info {
          margin-top: 8px;
          img {
            width: 30px;
            height: 30px;
            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
      .right {
        display: block;
        margin-top: 20px;
        .qq {
          display: flex;
          align-items: center;
          flex-direction: row;
          justify-content: unset;
          width: fit-content;
          margin: 0 auto;
          a,
          p {
            font-weight: 400;
            font-size: 12px;
            line-height: 13px;
            letter-spacing: 0.01em;
            &:first-child {
              margin-right: 12px;
            }
          }
        }
        .qq-gruop {
          margin: 0 auto;
          margin-top: 8px;
        }
        .qr-code {
          width: fit-content;
          margin: 0 auto;
          margin-top: 12px;
          .qr-code-box {
            .desc {
              font-weight: 400;
              font-size: 10px;
              line-height: 12px;
              color: #ffffff;
            }
          }
        }
      }
    }
    .line {
      margin:16px 0;
    }
    .copyright {
      font-size: 12px;
    }
  }
}
</style>
