<template>
  <div
    class="page-header"
    :style="{'box-shadow': routeName!='home'&&bodyWidth<1200?'0px 4px 4px rgba(0, 0, 0, 0.05)':''}"
  >
    <div class="main-container">
      <div class="df-c-b">
        <div class="sys-name" @click="goPage('home')">
          <img src="../../assets/home.png" v-if="routeName!='home'&&bodyWidth<1200" />
          <img src="../../assets/sys_title.png" v-else />
        </div>
        <div class="menu df-c">
          <div
            class="menu-item"
            @click="goPage('home')"
            :class="{'menu-item-active':routeName=='home'}"
          >首页</div>
          <div
            class="menu-item"
            @click="goPage('versionIntroduction')"
            :class="{'menu-item-active':routeName=='versionIntroduction'}"
          >功能介绍</div>
          <div class="menu-item df-c">
            <span>源码下载</span>
            <img src="../../assets/caret-down-small.png" width="16px" style="margin-left:4px" />
            <div class="menu-item-chidrens">
              <a
                class="second-menu"
                href="https://github.com/springreport/springreport"
                target="_blank"
              >github</a>
              <a
                class="second-menu"
                href="https://gitee.com/springreport/springreport"
                target="_blank"
              >gitee</a>
            </div>
          </div>
          <div
            class="menu-item"
            @click="openUrl('https://gitee.com/springreport/springreport/wikis/pages')"
            :class="{'menu-item-active':routeName=='video'}"
          >文档</div>
          <div
            class="menu-item"
            @click="goPage('record')"
            :class="{'menu-item-active':routeName=='record'}"
          >更新记录</div>
          <div
            class="menu-item"
            @click="goPage('contactUs')"
            :class="{'menu-item-active':routeName=='contactUs'}"
          >联系作者</div>
          <div class="menu-item df-c"  @click="openUrl('https://www.springreport.vip/SpringReport')">
            <span>立即试用</span>
          </div>
        </div>
        <div class="page-title" v-if="routeName!='home'" style="display:none">{{routeTitle}}</div>
        <div class="menu-list" @click="show=true">
          <img src="../../assets/view-list.png" />
        </div>
      </div>
    </div>

    <Popup v-model="show" position="left" :style="{ height: '100%',width:'100%' }">
      <div class="h5">
        <div class="df-c-b">
          <div class="sys-name">
            <img src="../../assets/sys_title.png" />
          </div>
          <div class="menu-list" @click="show=false">
            <img src="../../assets/view-list.png" />
          </div>
        </div>
        <div class="menu-h5">
          <div class="menu-h5-item" @click="goPage('home')" :class="{'active':routeName=='home'}">首页</div>
          <div
            class="menu-h5-item"
            @click="goPage('versionIntroduction')"
            :class="{'active':routeName=='versionIntroduction'}"
          >功能介绍</div>
          <!-- <div
            class="menu-h5-item"
             @click="openSourceNotify()"
            :class="{'active':routeName=='video'}"
          >源码下载</div> -->
          <div
            class="menu-h5-item df-c-b"
            @click="isOpenMenu = !isOpenMenu"
            style="padding:0;margin-bottom:8px"
          >
            <span>源码下载</span>
            <img
              src="../../assets/chevron-right.png"
              width="20px"
              :style="{'transform':isOpenMenu?'rotate(90deg)':'rotate(0deg)' }"
            />
          </div>
          <div class="menu-h5-item-chidrens" v-show="isOpenMenu">
            <a
              class="second-menu"
              href="https://github.com/springreport/springreport"
              target="_blank"
            >github</a>
            <a
              class="second-menu"
              href="https://gitee.com/springreport/springreport"
              target="_blank"
            >gitee</a>
          </div>
          <div
            class="menu-h5-item"
             @click="openUrl('https://gitee.com/springreport/springreport/wikis/pages')"
            :class="{'active':routeName=='video'}"
          >文档</div>
          <div
            class="menu-h5-item"
            @click="goPage('record')"
            :class="{'active':routeName=='record'}"
          >更新记录</div>
          <div
            class="menu-h5-item"
            @click="goPage('contactUs')"
            :class="{'active':routeName=='contactUs'}"
          >联系作者</div>
          <div
            class="menu-h5-item df-c-b"
            @click="openUrl('https://www.springreport.vip/SpringReport')"
            style="padding:0;margin-bottom:8px"
          >
            <span>立即试用</span>
          </div>
        </div>
      </div>
    </Popup>
  </div>
</template>

<script>
import Popup from 'vant/lib/popup'
import 'vant/lib/popup/style'
export default {
  name: 'PageHeader',
  data() {
    return {
      show: false,
      bodyWidth: 0,
      isOpenMenu: false,
    }
  },
  components: {
    Popup,
  },
  computed: {
    routeName() {
      return this.$route.name
    },
    routeTitle() {
      return this.$route.meta.title
    },
  },
  mounted() {
    this.bodyWidth = document.querySelector('body').clientWidth
    this.open1();
  },
  methods: {
    notify(){
      alert("因服务器配置原因，在线体验暂时只部署了vue2版本，请去vue2版本进行体验，功能基本上都是一致的，敬请谅解。")
    },
    openSourceNotify(){
      alert("开源准备中，请先加入微信群或者QQ群，正式开源后会在群中通知，初步计划七月底发布第一个版本，敬请期待...")
    },
    goPage(name) {
      console.log(this.routeName)
      this.show = false
      this.isOpenMenu = false
      if (name == this.routeName) {
        return
      }
      this.$router.push({
        name,
      })
    },
    openUrl(url){
      window.open(url, '_blank');
    },
    open1() {
        // this.$notify({
        //   title: '企业版更新记录',
        //   dangerouslyUseHTMLString: true,
        //   message:  '<div class="right-update" style="height:500px;width:316px;overflow:auto">'
        //    +'2024-06：</br>●报表和协同文档新增CSV文件导入支持</br>'
        //   +'2024-05：</br>●新增自定义word报表模板支持</br>'
        //   +'2024-04：</br>●文档更新并录制讲解视频</br>'
        //   +'2024-03：</br>●报表模板和协同文档权限控制支持精细到单元格</br>'
        //   +'2024-02：</br>●报表预览/打印支持横向分页配置</br>●excel上下标支持</br>●报表查询条件新增下拉树支持</br>●新增复杂小计支持</br>'
        //   +'●公式优化，15000+个公式操作不卡顿</br>●图表导出优化，支持导出excel可编辑图表</br>'
        //   +'2024-01：</br>●多租户支持</br>●协同文档新增分享模式</br>●报表设计和预览新增协同支持</br>'
        //   +'2023-12：</br>●支持直接打印</br>●打印和pdf支持设置页眉页脚水印</br>●填报报表和展示报表合并，支持不同类型的报表作为子报表进行跳转</br>'
        //   +'●填报报表支持导出excel，pdf和打印</br>●报表上传优化，放弃使用luckyexcel前端导入，使用后端方式解析导入</br>'
        //   +'2023-10-2023-11：</br>●优化luckysheet源码，可以加载更多数据，速度也更快'
        //   +'</br>●支持斜线表头</br>●重构协同文档功能，采用redis+mq的架构，可以支持50万+单元格数据'
        //   +'</br>2023-09：</br>●重写协同编辑功能，使协同编辑功能更丰富，更完善'+
        //   '</br>●协同编辑功能数据库适配：可以支持mysql，postgresql，oracle，sqlserver，达梦数据库，人大金仓数据库，可以方便的适配各类项目</br>2023-08：'+
        //   '</br>●新增报表定时任务</br>●报表新增图表组件：水球图，地图柱状图组合图</br>●报表新增动态列功能：根据不同的查询条件显示不用的列</br>'+
        //   '2023-07：</br>●项目数据库新增kingbase(人大金仓)支持</br>●报表支持手机端预览</br>●报表新增图表组件：仪表盘，雷达图，地图，漏斗图</br>'+
        //   '2023-06：</br>●新增报表分享功能</br>2023-05：</br>●excel的导入和导出新增下拉选择，批注信息，工作表保护等的支持</br>●新增报表下钻功能</br>●数据源新增es，TDengine支持</br>●分页报表导出支持导出指定页</br>●填报报表新增单元格联动校验</br>'+
        //   '2023-04：</br>●新增报表转pdf并在线预览</br>●新增单元格过滤条件</br>●新增图表功能，支持数据图表联动显示</br>●数据源新增clickhouse支持</br>'+
        //   '2023-03：</br>●报表新增数据字典支持</br>●动态单元格新增交替颜色设置</br>●项目数据库新增oracle支持</br>●报表预览优化</br></div>',
        //   duration: 0,
        //   position: 'bottom-right',
        // });

        // this.$notify({
        //   title: '免费使用',
        //   dangerouslyUseHTMLString: true,
        //   message:  '<b>企业版本地部署免费使用请<a target="_blank" href="https://gitee.com/pospos/SpringReport_local">点击下载</a>，线上环境体验请点击立即试用</b></br>'+
        //   '手机/微信：15954272163</br>'+
        //   "QQ：<a href='tencent://message/?uin=986539100'>986539100</a></br>"+
        //   "QQ群：<a href='https://jq.qq.com/?_wv=1027&k=5BgmyrIz'>1004742371</a>",
        //   duration: 0,
        //   position: 'top-left'
        // });
      },
  },
}
</script>

<style scoped lang="scss">
.page-header {
  width: 100%;
  height: 60px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  .sys-name {
    cursor: pointer;
    padding: 12px 0;
    img {
      height: 36px;
      display: block;
    }
  }
  .menu {
    &-item {
      cursor: pointer;
      height: 60px;
      line-height: 60px;
      min-width: 96px;
      box-sizing: border-box;
      text-align: center;
      padding: 0 16px;
      font-weight: 500;
      font-size: 16px;
      color: #17b794;
      transition: all 0.5s;
      position: relative;
      &:hover {
        background-color: #17b794;
        font-weight: 400;
        color: #ffffff;
        .menu-item-chidrens {
          display: block;
        }
      }
      .menu-item-chidrens {
        display: none;
        transition: all 0.5s;
        position: absolute;
        bottom: -104px;
        right: -1px;
        background: #ffffff;
        padding: 6px;
        border: 0.5px solid #dcdcdc;
        box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.05),
          0px 4px 5px rgba(0, 0, 0, 0.08), 0px 2px 4px -1px rgba(0, 0, 0, 0.12);
        border-radius: 2px;
        .second-menu {
          display: block;
          width: 88px;
          height: 43px;
          line-height: 43px;
          font-weight: 400;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.9);
          margin-bottom: 2px;
          &:hover {
            color: #17b794;
          }
        }
      }
    }
    &-item-active {
      background-color: #17b794;
      font-weight: 400;
      color: #ffffff;
    }
  }
  .menu-list {
    display: none;
  }
}
.h5 {
  width: 100%;
  height: 100vh;
  background-image: url('../../assets/h5-menu-bg.png');
  background-size: 100% 100%;
}
::v-deep .test{
  background: #17b794 !important;
}
@media screen and (max-width: 1200px) {
  .page-header {
    height: auto;
    box-sizing: border-box;
    box-shadow: unset;
    .df-c-b {
      padding: 0 16px;
    }
    .page-title {
      display: block !important;
      font-weight: 500;
      font-size: 18px;
      letter-spacing: 0.01em;
      color: rgba(0, 0, 0, 0.9);
    }
    .sys-name {
      padding: 10px 0;
      img {
        height: 24px;
        display: block;
      }
    }
    .menu {
      display: none;
    }
    .menu-list {
      display: block;
      img {
        width: 24px;
        height: 24px;
      }
    }
    .menu-h5 {
      margin-top: 36px;
      padding: 0 32px;
      .menu-h5-item {
        margin-bottom: 20px;
        height: 30px;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 30px;
        color: rgba(0, 0, 0, 0.9);
      }
      .active {
        color: #17b794;
      }
      .menu-h5-item-chidrens {
        a {
          text-indent: 1em;
          display: block;
          font-size: 16px;
          line-height: 30px;
          color: rgba(0, 0, 0, 0.9);
        }
      }
    }
  }
}
</style>
<style scoped>
::v-deep .right-update{
 display: none;
}
</style>
