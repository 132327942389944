<template>
  <div class="layout-page">
    <PageHeader class="header" />
    <div class="main">
      <router-view></router-view>
    </div>
    <PageFooter />
  </div>
</template>

<script>
import PageHeader from './components/PageHeader.vue'
import PageFooter from './components/PageFooter.vue'
export default {
  name: 'layoutPage',
  data() {
    return {}
  },
  components: {
    PageHeader,
    PageFooter,
  },
  methods: {},
}
</script>

<style scoped lang="scss">
.layout-page {
  .header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
  }
  .main {
    padding-top: 60px;
  }
}
@media screen and (max-width: 1200px) {
  .layout-page {
    .main {
      padding-top: 42px;
    }
  }
}

</style>
